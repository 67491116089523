<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      wrapClassName="cancelName"
      :width="457"
      :closable="false"
      centered
      :maskClosable="false"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel">
      <div class="modal_close" @click="handleCancel">
        <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
      </div>
      <div class="modal_title_box">
          <span class="modal_title">
            <i class="modal_title_line"></i>请选择退货类型
          </span>
      </div>
      <div class="returnType">
         <p>
           <span>*</span>退货类型：
         </p>
        <a-select class="choseBox" v-model="type"  placeholder="请选择">
          <a-select-option
            v-for="(item,index) in typeList"
            :key="index"
            :value=item.id
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>

    </a-modal>
  </div>
</template>
<script>
export default {
  components:{

  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },
  },

  data() {
    return {
      type:'',
      confirmLoading:false,
      typeList:[
        {
          id:1,
          name:'正品'
        },
        {
          id:2,
          name:'残次'
        },
      ]
    };
  },
  methods: {
    handleOk() {
      if (!this.type ){
        this.$message.warning('请选择退货类型')
        return
      }
      this.$emit("handOk", this.type);
      this.$emit("update:visible", false);
    },
    handleCancel(e) {
      this.type = ''
      this.$emit("update:visible", false);
    },

  },
};
</script>
<style scoped lang="less">


/deep/.ant-modal-content {
  width: 648px;
  border-radius: 10px;
}

/deep/.ant-modal-title {
  font-weight: 500;
  color: #262626;
  line-height: 22px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;


}

/deep/.ant-modal-header,
/deep/.ant-modal-footer {
  border: none;
}

/deep/.ant-modal-header::after {
  display: block;
  content: '';
  width: 100px;
  height: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
  // z-index:-1;
  background: #E4F8F7;
}

/deep/.ant-modal-close-x:hover {
  background: #E4F8F7;
}

/deep/.ant-modal-body {
  border: none;
  padding: auto;

}

/deep/.ant-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/.ant-modal-footer {
  /deep/.ant-btn{
    width: 120px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #CCCCCC;
    margin-left: 24px;
    margin-bottom: 30px;
  }

}
p>button {
  height: 32px;
  border-radius: 16px;
  text-align: center;
  line-height: 16px;
  color: #00AAA6;
  font-size: 13px;
  padding: 7px 14px;
  background-color: #fff;
  // margin-right: 9px;
  margin-left: 8px;
  border: 1px solid #EEEEEE;
  outline: none;
}
.leixing{
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    cursor: pointer;
  }
}

//p>span {
//    color: #D9161C;
//}

.active {
  color: #fff;
  background-color: #00AAA6;
}
/deep/ .cancelName{
  .ant-modal-content{
    text-align:center;
    border-radius: 10px;
    .ant-modal-body{
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      .cancelContent{
        display: flex;
        flex-wrap: wrap;
        width: 418px;
        .clickBtn{
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #00AAA6;
          margin-right: 8px;
          margin-bottom: 16px;
          cursor: pointer;
          font-size: 13px;
          font-weight: 400;
        }
        .clickBtnActive {
          cursor: pointer;
          margin-right: 8px;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          //border: 1px solid #EEEEEE;
          display: flex;
          background: #00AAA6;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-bottom: 16px;
          border: none;
          font-size: 13px;
          font-weight: 400;
        }
      }
      .cancelInfo{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        margin-bottom: 24px;
        text-align: left;
        overflow: hidden;
        .cancleZi{
          width: 70px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          margin-right: 8px;
          text-align: right;
        }
        .ant-input{
          width: 300px;
          height:32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        .ant-input-affix-wrapper{
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;
        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #E4F8F7;
        .modal_close_icon {
          color: #08ABA8;
        }
      }
      .modal_title_box {
        text-align: center;
        padding-bottom:50px;
        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing:1px;
          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
      .returnType{
        display: flex;
        align-items: center;
        p{
          color:#262626;
          span{
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #D9161C;
            line-height: 20px;
          }
          margin-right: 9px;
        }
        .choseBox{
          width: 300px;
          height: 32px;
          background: #F7F7F7;
          border-radius: 4px;
        }

      }
    }
    .ant-modal-footer{
      text-align: center;
      border-top:0px solid #fff;
      padding:60px 0 30px;
      .ant-btn{
        width: 120px;
        height:40px;
        border-radius: 4px;
        font-size: 16px;
        color:#777;
      }
      .ant-btn-primary{
        color: #fff;
        background:#00AAA6;
        margin-left:24px;
      }
    }
  }
}
</style>
